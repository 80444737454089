import classes from "./PlanningEmployee.module.scss";
import HeaderPlanningEmployee from "./HeaderPlanningEmployee/HeaderPlanningEmployee";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { UserInfoContext } from "context/UserInfoContext";
import { wordDeclension } from "helpers/wordDeclension";
import { getCheckedWeek } from "helpers/checkedWeek";
import { splitDate } from "helpers/dates";
import { useContext, useEffect, useState } from "react";
import { PBookings, PBookingsProjectInfo, PlannedInfo, WeekInfo } from "../dto";

const PlanningEmployee = () => {
  const [weekInfo, setWeekInfo] = useState<WeekInfo[]>();
  const [plannedInfo, setPlannedInfo] = useState<PlannedInfo>();
  const [startDate, setStartDate] = useState(new Date());
  const { apiGet } = useApi();
  const { userInfoContext } = useContext(UserInfoContext);
  const userId = userInfoContext?.data.id;
  const checkedWeek = getCheckedWeek(startDate);

  useEffect(() => {
    const mondayFormated = splitDate(checkedWeek[0].toLocaleDateString());
    const sundayFormated = splitDate(checkedWeek[6].toLocaleDateString());

    const getEmployeePlannedInfo = async () => {
      const res = await apiGet(endpoints.planningEmployeeList, {
        date_start: mondayFormated,
        date_end: sundayFormated,
        employee: userId,
      });

      if (res?.data) {
        setPlannedInfo(res.data[0]);
      }
    };

    const getCurrentWeekData = async () => {
      const res = await apiGet(endpoints.planningDayList, {
        date_start: mondayFormated,
        date_end: sundayFormated,
      });

      if (res.data) {
        setWeekInfo(res.data);
      }
    };

    getCurrentWeekData();
    getEmployeePlannedInfo();
  }, [startDate]);

  return (
    <div className={classes.container}>
      <HeaderPlanningEmployee
        userInfo={plannedInfo}
        startDate={startDate}
        setStartDate={setStartDate}
        checkedWeek={checkedWeek}
      />
      <div className={classes.table}>
        <div className={classes.header}>
          {weekInfo?.map((day: WeekInfo) => (
            <div
              key={day.date}
              className={day.is_workday ? classes.weekday : classes.dayoff}
            >
              <div className={classes.date}>{day.day_month}</div>
              <div className={classes.day}>{day.day_of_week}</div>
            </div>
          ))}
        </div>
        <div className={classes.columns}>
          {plannedInfo?.bookings.map((week: PBookings, ind: number) => {
            if (week.employment !== 0) {
              return (
                <div key={ind} className={classes.column}>
                  {week.project_info_list.map(
                    (day: PBookingsProjectInfo, ind: number) => {
                      return (
                        <div key={ind} className={classes.project}>
                          <div className={classes["project--name"]}>
                            {day?.project_name}
                          </div>
                          <div className={classes["project--hours"]}>
                            {`${day?.hours} `}
                            {wordDeclension(day?.hours, [
                              "час",
                              "часа",
                              "часов",
                            ])}
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              );
            } else {
              return (
                <div key={ind} className={classes.column}>
                  <div key={ind} className={classes.project}>
                    <div className={classes["project--hours"]}>0 часов</div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default PlanningEmployee;
