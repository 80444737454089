import { formatTime } from "helpers/formatTime";
import { TProject } from "components/tables/components/dto";


export const makeRowsFormattedTime = (rows: TProject[]) => {
  return rows.map((item) => {
    const entries = Object.entries(item) as [string, string | number][];

    const oneRow: (string | number)[] = entries.map(([key, value]) => {
      if (key === "spent_time_min") {
        return formatTime(`${value}`);
      }
      return value;
    });
    return oneRow;
  });
};
