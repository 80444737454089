import Select, {
  CSSObjectWithLabel,
  ControlProps,
  OptionProps,
  StylesConfig,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { OptionType } from "./SelectWrapper";

type Props = {
  name: string;
  disabled?: boolean | undefined;
  readOnly?: boolean | undefined;
  onChange?: (data: any) => void;
  inputArgs?: object | undefined;
  placeholder?: string | undefined;
  error?: string;
  required?: boolean;
  multiple?: boolean;
  autoFocus?: boolean;
  form?: string;
  options?: OptionType[];
  id?: string;
  defaultValue?: OptionType | OptionType[];
  value?: OptionType | OptionType[] | null;
  searchable?: boolean;
  optionStyle?: React.CSSProperties;
  isClearable?: boolean;
};

const SelectInput = ({
  name,
  placeholder,
  disabled,
  required,
  autoFocus,
  form,
  multiple,
  onChange,
  options,
  id,
  value,
  defaultValue,
  error,
  searchable = true,
  optionStyle,
  isClearable,
}: Props) => {
  const styles: StylesConfig<OptionType> = {
    input: (styles: CSSObjectWithLabel) => ({
      ...styles,
      paddingTop: 0,
      height: "fit-content",
      marginTop: "-2px",
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: "30px",
      height: "24px",
      position: "absolute",
      top: "-6px",
      right: "-27px",
    }),
    singleValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: "#b6b6b6",
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "16px",
    }),

    menu: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: "max-content",
      minWidth: "100%",
    }),

    option: (
      styles: CSSObjectWithLabel,
      { isDisabled }: OptionProps<OptionType>,
    ) => {
      return {
        ...styles,
        color: "#b6b6b6",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "16px",
        background: isDisabled ? "#f2f2f2" : "none",
        textAlign: "center",
        cursor: isDisabled ? undefined : "pointer",
        transition: "color 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          background: isDisabled ? undefined : "none",
          color: isDisabled ? undefined : "#00c2fa",
        },
        ...optionStyle,
      };
    },
    control: (
      styles: CSSObjectWithLabel,
      { isDisabled, isFocused }: ControlProps<OptionType>,
    ) => {
      return {
        ...styles,
        boxShadow:
          "0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 4px rgba(0, 0, 0, 0.13)",
        minHeight: "24px",
        cursor: "pointer",
        border: "none",
        outline: isDisabled
          ? "none"
          : isFocused
            ? "1px solid #00c2fa"
            : error
              ? "1px solid #ff7676"
              : "none",
        ":hover": {
          ...styles[":hover"],
          border: "none",
        },
      };
    },
    valueContainer: (styles: CSSObjectWithLabel) => ({
      ...styles,
      padding: "4px 25px",
      justifyContent: "center",
      height: "fit-content",
    }),
    indicatorSeparator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      display: "none",
    }),
    indicatorsContainer: (styles: CSSObjectWithLabel) => ({
      ...styles,
      padding: 0,
      position: "absolute",
      right: "25px",
    }),
    clearIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      position: "absolute",
      right: "-10px",
      top: "-5px",
      cursor: "pointer",
      ":hover": {
        color: "#00c2fa",
      },
    }),
    container: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: "100%",
    }),
    placeholder: (styles: CSSObjectWithLabel) => ({
      ...styles,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "5px",
    }),
  };

  return isClearable ? (
    <CreatableSelect
      menuPortalTarget={document.body}
      formatCreateLabel={(userInput) => `Создать: ${userInput}`}
      menuPosition={"fixed"}
      styles={styles}
      isSearchable={searchable}
      isDisabled={disabled}
      autoFocus={autoFocus}
      isMulti={multiple}
      options={options}
      isOptionDisabled={(option) =>
        option.isdisabled ? option.isdisabled : false
      }
      id={id}
      placeholder={placeholder}
      name={name}
      required={required}
      defaultValue={defaultValue} //uncontrolled components
      value={value} //controlled components
      form={form}
      onChange={onChange}
    />
  ) : (
    <Select
      menuPortalTarget={document.body}
      noOptionsMessage={() => "Ничего не найдено"}
      menuPosition={"fixed"}
      styles={styles}
      isSearchable={searchable}
      isDisabled={disabled}
      autoFocus={autoFocus}
      isMulti={multiple}
      onChange={onChange}
      options={options}
      isOptionDisabled={(option) =>
        option.isdisabled ? option.isdisabled : false
      }
      id={id}
      placeholder={placeholder}
      name={name}
      required={required}
      defaultValue={defaultValue} //uncontrolled components
      value={value} //controlled components
      form={form}
    />
  );
};

export default SelectInput;
