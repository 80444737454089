import classes from "./UserEmployment.module.scss";
import Clock from "components/ui/icons/Clock";
import Arrow from "components/ui/icons/Arrow";
import UserEmploymentСolumn from "../user-employment-column/UserEmploymentСolumn";
import { Dispatch, SetStateAction, useState } from "react";
import { PBookings } from "components/planning/dto";
import { useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as swiper } from "swiper";
import { Controller } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

interface Props {
  data: PBookings[];
  id: number;
  setThumb: Dispatch<SetStateAction<swiper[]>>;
}

const UserEmployment = ({ data, id, setThumb }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const arrowClass = isOpen
    ? [classes["arrow-icon"], classes["arrow-icon--open"]].join(" ")
    : classes["arrow-icon"];

  return (
    <div className={classes.container}>
      <div
        className={classes["totals-row"]}
        data-unit={searchParams.get("time_unit")}
      >
        {searchParams.get("time_unit") !== "month" ? (
          data.map((i: PBookings, idx: number) => {
            return (
              <div className={classes.cellWrapper} key={idx}>
                <div
                  className={
                    !i.total_hours ? classes.dayOff : classes["total-cell"]
                  }
                >
                  <Clock clazz={classes["clock-icon"]} />
                  <span>{`${i.employment} ч.`}</span>
                </div>
                {(searchParams.get("time_unit") === "week" ||
                  searchParams.get("time_unit") === "month") && (
                  <div className={classes.progress}>
                    <div className={classes.progress__bar}>
                      <div
                        className={classes["progress__bar--fill"]}
                        style={{
                          width: `${
                            (i.employment * 100) / i.total_hours <= 100
                              ? (i.employment * 100) / i.total_hours
                              : 100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <div
                      className={classes.progress__text}
                    >{`${i.employment}ч`}</div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <Swiper
            className={classes.slider}
            modules={[Controller]}
            watchSlidesProgress={true}
            onSwiper={(swiper) => setThumb((prev) => [...prev, swiper])}
            slidesPerView={6}
            spaceBetween={20}
          >
            {data.map((i: PBookings, idx: number) => {
              return (
                <SwiperSlide className={classes.cellWrapper} key={idx}>
                  <div
                    className={
                      !i.total_hours ? classes.dayOff : classes["total-cell"]
                    }
                  >
                    <Clock clazz={classes["clock-icon"]} />
                    <span>{`${i.employment} ч.`}</span>
                  </div>
                  {(searchParams.get("time_unit") === "week" ||
                    searchParams.get("time_unit") === "month") && (
                    <div className={classes.progress}>
                      <div className={classes.progress__bar}>
                        <div
                          className={classes["progress__bar--fill"]}
                          style={{
                            width: `${
                              (i.employment * 100) / i.total_hours <= 100
                                ? (i.employment * 100) / i.total_hours
                                : 100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div
                        className={classes.progress__text}
                      >{`${i.employment}ч`}</div>
                    </div>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
      {isOpen && (
        <div className={classes.row}>
          {searchParams.get("time_unit") !== "month" ? (
            data.map((i: PBookings, idx: number) => {
              return !i.total_hours ? (
                <span key={idx} className={classes.dayOff}>
                  0 ч.
                </span>
              ) : (
                <UserEmploymentСolumn key={idx} data={i} id={id} />
              );
            })
          ) : (
            <Swiper
              className={classes.slider}
              modules={[Controller]}
              watchSlidesProgress={true}
              onSwiper={(swiper) => setThumb((prev) => [...prev, swiper])}
              slidesPerView={6}
              spaceBetween={20}
            >
              {data.map((i: PBookings, idx: number) => {
                return !i.total_hours ? (
                  <SwiperSlide key={idx} className={classes.dayOff}>
                    0 ч.
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={idx}>
                    <UserEmploymentСolumn data={i} id={id} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      )}
      <div className={classes.arrow} onClick={() => setIsOpen(!isOpen)}>
        <Arrow clazz={arrowClass} />
      </div>
    </div>
  );
};

export default UserEmployment;
