import { Routes, Route } from "react-router-dom";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import EducationPage from "pages/EducationPage/EducationPage";
import EmploymentCalendarPage from "pages/EmploymentCalendarPage/EmploymentCalendarPage";
import LoggingPage from "pages/LoggingPage/LoggingPage";
import ProjectCasePage from "pages/ProjectsCasePage/ProjectCasePage";
import ProjectsPage from "pages/ProjectsPage/ProjectsPage";
import ReportsPage from "pages/ReportsPage/ReportsPage";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import AuthPage from "pages/AuthPage/AuthPage";
import ProtectedRoute from "components/protected-route/ProtectedRoute";
import AuthLayout from "components/layouts/AuthLayout/AuthLayout";
import BasicLayout from "components/layouts/BasicLayout/BasicLayout";
import AuthRecoveryPasswordPage from "pages/AuthPage/AuthRecoveryPasswordPage/AuthRecoveryPassword";
import AuthConfirmCodePage from "pages/AuthPage/AuthConfirmCodePage/AuthConfirmCodePage";
import AuthNewPasswordPage from "pages/AuthPage/AuthNewPasswordPage/AuthNewPasswordPage";
import { AuthFormContext } from "context/AuthFormContext";
import PlanningPage from "pages/PlanningPage/PlanningPage";
import { useState } from "react";

const Router = () => {
  const [user, setUser] = useState<string>("");
  const [token, setToken] = useState<string>("");

  return (
    <Routes>
      <Route
        path="/auth"
        element={
          <AuthLayout>
            <AuthPage />
          </AuthLayout>
        }
      ></Route>
      <Route
        path="/auth/recovery-password"
        element={
          <AuthFormContext.Provider value={{ user, setUser, token, setToken }}>
            <AuthLayout>
              <AuthRecoveryPasswordPage />
            </AuthLayout>
          </AuthFormContext.Provider>
        }
      ></Route>
      <Route
        path="/auth/confirm-code"
        element={
          <AuthFormContext.Provider value={{ user, setUser, token, setToken }}>
            <AuthLayout>
              <AuthConfirmCodePage />
            </AuthLayout>
          </AuthFormContext.Provider>
        }
      ></Route>
      <Route
        path="/auth/new-password"
        element={
          <AuthFormContext.Provider value={{ user, setUser, token, setToken }}>
            <AuthLayout>
              <AuthNewPasswordPage />
            </AuthLayout>
          </AuthFormContext.Provider>
        }
      ></Route>
      <Route
        path="/logging"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <LoggingPage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <ProfilePage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/planning"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <PlanningPage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/education"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <EducationPage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/employment"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <EmploymentCalendarPage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/projects"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <ProjectsPage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/projects/:id"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <ProjectCasePage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/reports"
        element={
          <ProtectedRoute>
            <BasicLayout>
              <ReportsPage />
            </BasicLayout>
          </ProtectedRoute>
        }
      ></Route>
      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
};
export default Router;
