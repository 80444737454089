import Telegram from "components/ui/icons/Telegram";
import { WidgetComponent } from "forms/components/widget";
import classes from "./TextInput.module.scss";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { useState } from "react";

const TextInput = ({
  label,
  name,
  placeholder,
  value,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  onChange,
  required,
  autoFocus,
  resDataAfterSubmit,
  form,
}: WidgetComponent) => {
  const elId = `id_${name}`;

  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string[] | null>(
    null,
  );

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data, errorMessage } = resDataAfterSubmit;
      if (Object.entries(errorMessage).length !== 0) {
        if (errorMessage[name]) setErrorAfterSubmit(errorMessage[name]);
      }
    }
  }, [resDataAfterSubmit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorAfterSubmit(null);
    onChange(e.target.value);
  };
  const labelAndValue = form === "projectEditForm";

  return (
    <section
      className={[classes.container, classes[name]].join(" ")}
      data-error={errorAfterSubmit ? true : undefined}
    >
      {labelAndValue ? (
        <label>
          <span>{label}</span>
          <span>{Number(value) ? `${value} ч.` : value?.toString()}</span>
        </label>
      ) : (
        label && (
          <label htmlFor={elId}>
            {placeholder === "Новый аккаунт" && (
              <Telegram clazz={`${classes.icon} ${classes["icon--fill"]}`} />
            )}
            {label}
          </label>
        )
      )}
      {name !== "spentTime" && (
        <input
          form={form}
          autoFocus={autoFocus}
          className={classes.input}
          placeholder={placeholder}
          name={name}
          id={elId}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          defaultValue={!(value instanceof Date) && value ? value : ""}
          type="text"
          {...(inputArgs || {})}
        ></input>
      )}
      {errorAfterSubmit?.map((i, idx) => {
        return (
          <ErrorComponent key={idx} clazz={classes.error}>
            {`*${i}`}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default TextInput;
