import classes from "./UserEmploymentСolumn.module.scss";
import Form from "forms/components/form";
import { endpoints } from "API/endpoints";
import CrossFill from "components/ui/icons/CrossFill";
import { useApi } from "hooks/useApi/useApi";
import { ApiDataType, FormDataType } from "types";
import { useState } from "react";
import { PBookings, PBookingsProjectInfo } from "components/planning/dto";
import EditTask from "components/ui/icons/EditTask";
import TableInputContainer from "components/tables/components/TableInputContainer";
import TextInput from "forms/components/simpleWidgets/text-input/TextInput";
import Delete from "components/ui/icons/Delete";

interface Props {
  data: PBookings;
  id: number;
}

const UserEmploymentСolumn = ({ data, id }: Props) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isRedact, setIsRedact] = useState(-1);
  const [timeValue, setTimeValue] = useState("");
  const [helpText, setHelpText] = useState<string | null>(null);
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);

  const { apiPost, apiPut, apiDelete } = useApi();

  const onSubmitPlanning = async (form: FormDataType) => {
    console.log(Object.assign({}, form, { date: data.date, employee: id }));

    const planningData = Object.assign({}, form, {
      date: data.date,
      employee: id,
    });

    const res = await apiPost(
      endpoints.planningEmployee,
      planningData,
      undefined,
      true,
    );

    setResDataAfterSubmit(res);

    if (res.data) {
      setIsOpenForm(false);
    }
  };

  const handleChangeTimeInput = (value: string) => {
    Number.isInteger(Number(value))
      ? setHelpText(null)
      : setHelpText("Введите целое число");
    setTimeValue(value);
  };

  const handleDelete = async (id: number) => {
    if (typeof id === "number") {
      // Нужно ид конкретной записи
      await apiDelete(endpoints.planningEmployee + "2/");
    }
  };

  const handleSubmitTimeInput = async (time: string, projectName: string) => {
    if (time && timeValue) {
      if (Number.isInteger(Number(time))) {
        // Нужен добавить ид
        await apiPut(`${endpoints.planningEmployee}5/`, {
          employee: id,
          date: data.date,
          project_name: projectName,
          hours: +time,
          time_unit: "day",
        });
      }
    }
    setIsRedact(-1);
  };

  const skeleton = {
    count: 4,
    style: {
      width: "100%",
    },
    containerClassName: classes.skeleton,
  };

  return (
    <div className={classes.column}>
      {data.project_info_list?.map(
        (project: PBookingsProjectInfo, idx: number) => {
          return (
            <div key={idx} className={classes.cell}>
              <div className={classes.name}>
                {project.project_name}
                {/* Нужно передавать ид конкретной записи */}
                <span onClick={() => handleDelete(idx)}>
                  <Delete clazz={classes.delete} />
                </span>
              </div>

              {isRedact === idx ? (
                <TableInputContainer
                  className={classes.inputContainer}
                  onBlur={() =>
                    handleSubmitTimeInput(timeValue, project.project_name)
                  }
                  onKeyDown={() =>
                    handleSubmitTimeInput(timeValue, project.project_name)
                  }
                >
                  <TextInput
                    name={"timeInputPlanning"}
                    value={timeValue}
                    onChange={handleChangeTimeInput}
                    autoFocus={true}
                    fieldClass={classes.timeInput}
                    resDataAfterSubmit={
                      typeof helpText === "string"
                        ? {
                          data: null,
                          errorMessage: { timeInputPlanning: [helpText] },
                        }
                        : null
                    }
                  />
                </TableInputContainer>
              ) : (
                <div className={classes.hours} onClick={() => setIsRedact(idx)}>
                  {`${timeValue || project.hours} ч.`}
                  <EditTask clazz={classes.edit} />
                </div>
              )}
            </div>
          );
        },
      )}
      {isOpenForm ? (
        <Form
          formId={"planningCreate" + data.date}
          apiUrl={endpoints.planningForm}
          classValueForm={[classes.form, "planning-form"].join(" ")}
          btnText="+ Добавить"
          classValueBtn={classes.formBtn}
          onSubmit={onSubmitPlanning}
          skeleton={skeleton}
          resDataAfterSubmit={resDataAfterSubmit}
        >
          <button
            type="button"
            title="Закрыть"
            className={classes.close}
            onClick={() => setIsOpenForm(false)}
          >
            <CrossFill />
          </button>
        </Form>
      ) : (
        <button
          type="button"
          className={classes["form-button"]}
          onClick={() => setIsOpenForm(true)}
        >
          + Добавить
        </button>
      )}
    </div>
  );
};

export default UserEmploymentСolumn;
