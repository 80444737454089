import ErrorComponent from "components/common/error-component/ErrorComponent";
import Popup from "components/common/popup/Popup";
import Delete from "components/ui/icons/Delete";
import EditTask from "components/ui/icons/EditTask";
import { UserRoleContext } from "context/RoleContext";
import { formatTime } from "helpers/formatTime";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import Skeleton from "react-loading-skeleton";
import { getInputCellLoggingTable } from "helpers/tables/getInputCellLoggingTable";
import { makeRowsFormattedTime } from "helpers/tables/makeRowsFormattedTime";
import { onClickOnCell } from "helpers/tables/setInputOnClick";
import useApiTable from "hooks/useApiTable/useApiTable";
import { SkeletonType } from "types";
import TableInputContainer from "./../components/TableInputContainer";
import classes from "./LoggingTable.module.scss";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import TableMobile from "./TableMobile/TableMobile";
import { TableResponse } from "components/tables/components/dto";
import Pagination from "components/common/pagination/Pagination";
import { useCallback, useContext, useEffect, useState } from "react";

type TLoggingTable = {
  skeleton?: SkeletonType;
  onlyOnceSkeleton?: boolean;
  loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>;
  apiUrl: string;
  params?: URLSearchParams;
  testData?: () => TableResponse;
  updateTable?: boolean;
  setUpdateTable?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  pagination?: boolean;
  editable?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};

const LoggingTable = ({
  apiUrl,
  updateTable,
  setUpdateTable,
  params,
  testData,
  pagination,
  skeleton,
  children,
  onlyOnceSkeleton,
  loadingCallback,
}: TLoggingTable) => {
  const table = useApiTable(
    apiUrl,
    params,
    testData,
    updateTable,
    setUpdateTable,
  );

  const { width = 0 } = useWindowSize();

  const rows = makeRowsFormattedTime(table.data);

  const { userRoleContext } = useContext(UserRoleContext);

  const [isShowSkeleton, setIsShowSkeleton] = useState<boolean>(true);
  const [showErrorForEdit, setShowErrorForEdit] = useState<string[] | null>(
    null,
  );

  const [showInputForEdit, setShowInputForEdit] = useState<number[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [dateUpdate, setDateUpdate] = useState<{
    indexCell: number;
    taskId: number;
  } | null>(null);

  const updateData = useCallback(
    async (indexCell: number, taskId: number) => {
      if (!inputValue) {
        return setShowInputForEdit([]);
      }

      let timeInputValue;

      if (indexCell === 4) timeInputValue = formatTime(inputValue);

      const res = await table.updateData(
        taskId,
        null,
        indexCell,
        timeInputValue || inputValue,
      );

      if (res.errorMessage.length) {
        setShowErrorForEdit(res.errorMessage);
      }
      setShowInputForEdit([]);
      setDateUpdate(null);
      setInputValue("");
      setUpdateTable?.(true);
    },
    [inputValue],
  );

  useEffect(() => {
    if (dateUpdate) {
      updateData(dateUpdate.indexCell, dateUpdate.taskId);
    }
  }, [inputValue]);

  useEffect(() => {
    if (!dateUpdate && !inputValue && showInputForEdit.length !== 0) {
      setShowInputForEdit([]);
    }
  }, [dateUpdate]);

  const deleteData = useCallback(async (taskId: number) => {
    const res = await table.deleteData(taskId);
    if (res) {
      setUpdateTable?.(true);
    }
  }, []);

  const chooseTypeOfCell = (
    indexRow: number,
    indexCell: number,
    cell: string | number,
    taskId: number,
  ) => {
    if (showInputForEdit[0] === indexRow && showInputForEdit[1] === indexCell) {
      const childrenInputComponent = getInputCellLoggingTable({
        indexCell,
        cell,
        setInputValue,
        taskId,
        setDateUpdate,
      });

      return (
        <TableInputContainer
          className={classes.inputContainer}
          onBlur={() => {
            if (!dateUpdate) updateData(indexCell, taskId);
          }}
          onKeyDown={() => {
            if (!dateUpdate) updateData(indexCell, taskId);
          }}
        >
          {childrenInputComponent}
        </TableInputContainer>
      );
    } else {
      const editDate = userRoleContext.data
        .can_choose_date_project_time_tracking && (
        <EditTask clazz={classes.edit} />
      );

      const containerClass = classes.cellContainer;

      const containerClassWithDate =
        indexCell === 3
          ? editDate
            ? containerClass
            : [classes.cellContainer, classes.cellContainerSimple].join(" ")
          : containerClass;

      if (indexCell !== 0) {
        return (
          <div
            className={containerClassWithDate}
            onClick={() =>
              onClickOnCell(
                indexCell,
                indexRow,
                userRoleContext.data.can_choose_date_project_time_tracking,
                setShowInputForEdit,
              )
            }
          >
            {indexCell === 3 ? editDate : <EditTask clazz={classes.edit} />}
            {<p title={cell.toString()}>{cell}</p>}
          </div>
        );
      }
    }
  };

  useEffect(() => {
    if (table.isLoading) {
      loadingCallback?.(true);
    } else {
      loadingCallback?.(false);
    }
  }, [table.isLoading]);

  useEffectOnlyOnUpdate(() => {
    if (table.columns.length && onlyOnceSkeleton) setIsShowSkeleton(false);
  }, [table.columns]);

  if (table.isLoading && isShowSkeleton && skeleton) {
    const defaultStyle = {
      display: "block",
      width: "100%",
      height: "20px",
    };

    const style = { ...defaultStyle, ...skeleton?.style };
    const containerClassName = skeleton?.containerClassName
      ? [classes["skeleton-container"], skeleton.containerClassName].join(" ")
      : classes["skeleton-container"];

    return (
      <Skeleton
        containerClassName={containerClassName}
        count={skeleton?.count || 6}
        style={style}
        circle={skeleton?.circle}
      />
    );
  }

  return (
    <div className={classes.container}>
      {width < 1475 ? (
        <TableMobile
          rows={rows}
          table={table}
          setUpdateTable={setUpdateTable}
        />
      ) : (
        <div className={classes.table}>
          <div className={classes.header}>
            {table.columns.map((item) => {
              return (
                <div key={item.name} className={classes.header__item}>
                  <p>{item.label}</p>
                </div>
              );
            })}
          </div>
          {children}
          <div className={classes.rows}>
            {rows.map((item, indexRow) => {
              return (
                <div className={classes.oneRow} key={item[0]}>
                  {item.map((cell: string | number, indexCell: number) => {
                    return (
                      <div className={classes.cell} key={indexRow + indexCell}>
                        {chooseTypeOfCell(
                          indexRow,
                          indexCell,
                          cell,
                          Number(item[0]),
                        )}
                      </div>
                    );
                  })}
                  <div
                    className={classes.delete}
                    onClick={() => deleteData(Number(item[0]))}
                  >
                    <Delete />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {pagination && (
        <div className={classes.pagination}>
          <Pagination
            pages={table.pages}
            setPages={table.setPages}
            isLoading={table.isLoading}
          />
        </div>
      )}
      {showErrorForEdit ? (
        <Popup clazz={classes.popup} clickCallback={setShowErrorForEdit}>
          {showErrorForEdit.map((text, idx) => (
            <ErrorComponent
              key={idx}
              clazz={classes.error}
            >{`*${text}`}</ErrorComponent>
          ))}
        </Popup>
      ) : null}
    </div>
  );
};

export default LoggingTable;
