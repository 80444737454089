import classes from "./CalendarDatePicker.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale/ru";
import {
  addWeeks,
  subDays,
  previousMonday,
  addMonths,
  isMonday,
  format,
  nextSunday,
} from "date-fns";
import { registerLocale } from "react-datepicker";
import Calendar2 from "components/ui/icons/Calendar2";
import { createParams } from "helpers/createParams";
import { splitDate } from "helpers/dates";
import { HTMLProps, forwardRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Arrow2 from "components/ui/icons/Arrow2";

const CalendarDatePicker = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    searchParams.get("time_unit") || "day",
  );
  const [startDate, setStartDate] = useState(
    searchParams.get("date_start") !== null
      ? new Date(searchParams.get("date_start") || "")
      : new Date(),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchParams.get("date_end") !== null
      ? new Date(searchParams.get("date_end") || "")
      : null,
  );

  registerLocale("ru", ru);

  const toUpperFirstLetter = (str: string) =>
    str.replace(str[0], str[0].toUpperCase());

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleChangeDate = (date: Date) => {
    let start = date,
      end = date;

    if (selectedOption === "day") {
      start = isMonday(date) ? date : previousMonday(date);
      end = addWeeks(start, 1);
      end = subDays(end, 1);

      setSearchParams(
        createParams(searchParams, {
          date_start: splitDate(start.toLocaleDateString()),
          date_end: splitDate(end.toLocaleDateString()),
          time_unit: "day",
        }),
      );
    }

    if (selectedOption === "week") {
      start = isMonday(date) ? date : previousMonday(date);
      end = addMonths(start, 1);
      end = nextSunday(end);

      setSearchParams(
        createParams(searchParams, {
          date_start: splitDate(start.toLocaleDateString()),
          date_end: splitDate(end.toLocaleDateString()),
          time_unit: "week",
        }),
      );
      start = date;
    }

    if (selectedOption === "month") {
      end = addMonths(date, 12);
      end = subDays(end, 1);

      setSearchParams(
        createParams(searchParams, {
          date_start: splitDate(date.toLocaleDateString()),
          date_end: splitDate(end.toLocaleDateString()),
          time_unit: "month",
        }),
      );
    }

    setStartDate(start);
    setEndDate(end);
  };

  const ExampleCustomInput = forwardRef<
    HTMLButtonElement,
    HTMLProps<HTMLButtonElement>
  >(({ onClick }, ref) => (
    <button
      className={isActive ? classes.activeInput : classes.input}
      ref={ref}
      onClick={onClick}
    >
      <div className={classes.date}>
        <Calendar2 clazz={classes.calendarIcon} />
        {selectedOption === "day" &&
          startDate.toLocaleDateString() +
            (endDate ? " - " + endDate?.toLocaleDateString() : "")}
        {selectedOption === "week" &&
          toUpperFirstLetter(format(startDate, "LLLL", { locale: ru }))}
        {selectedOption === "month" && format(startDate, "yyyy")}
      </div>
      <Arrow2 clazz={classes.arrowIcon} />
    </button>
  ));
  ExampleCustomInput.displayName = "ExampleCustomInput";

  const renderMonthContent = (
    month: number,
    shortMonth: string,
    longMonth: string,
  ) => {
    return longMonth;
  };

  return (
    <div className={classes.calendar}>
      <DatePicker
        calendarClassName={classes.calendarContainer}
        selected={startDate}
        onChange={handleChangeDate}
        customInput={<ExampleCustomInput />}
        onCalendarOpen={() => setIsActive(true)}
        onCalendarClose={() => setIsActive(false)}
        locale="ru"
        dateFormat="dd.MM.yyyy"
        renderMonthContent={renderMonthContent}
        yearItemNumber={6}
        showWeekPicker={selectedOption === "day"}
        showMonthYearPicker={selectedOption === "week"}
        showYearPicker={selectedOption === "month"}
        renderCustomHeader={() => {
          return (
            <div className={classes.radioContainer}>
              <div
                className={classes.radio}
                onClick={() => handleOptionChange("day")}
              >
                <input
                  type="radio"
                  value="day"
                  name="day"
                  checked={selectedOption === "day"}
                  readOnly
                />
                <label className={classes.label} htmlFor="day">
                  Неделя
                </label>
              </div>
              <div
                className={classes.radio}
                onClick={() => handleOptionChange("week")}
              >
                <input
                  type="radio"
                  value="week"
                  name="week"
                  checked={selectedOption === "week"}
                  readOnly
                />
                <label className={classes.label} htmlFor="week">
                  Месяц
                </label>
              </div>
              <div
                className={classes.radio}
                onClick={() => handleOptionChange("month")}
              >
                <input
                  type="radio"
                  value="month"
                  name="month"
                  checked={selectedOption === "month"}
                  readOnly
                />
                <label className={classes.label} htmlFor="month">
                  Год
                </label>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CalendarDatePicker;
