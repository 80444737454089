import classes from "./PlanningPage.module.scss";
import { UserRoleContext } from "context/RoleContext";
import PlanningManager from "components/planning/planning-manager/PlanningManager";
import PlanningEmployee from "components/planning/planning-employee/PlanningEmployee";
import { useContext, useEffect } from "react";

const PlanningPage = () => {
  useEffect(() => {
    document.title = "ERP - Planning";
  }, []);

  const { userRoleContext } = useContext(UserRoleContext);

  return Object.entries(userRoleContext.data).length ? (
    userRoleContext.data?.can_create_project ? (
      <section className={classes.container}>
        <PlanningManager />
      </section>
    ) : (
      <section className={classes.mainContainer}>
        <PlanningEmployee />
      </section>
    )
  ) : null;
};

export default PlanningPage;
