/* eslint-disable @typescript-eslint/no-unused-vars */
import classes from "./Main.module.scss";
import TableHeader from "components/planning/planning-manager/table-header/TableHeader";
import TableItem from "../table-item/TableItem";
import PageInfo from "../page-info/PageInfo";
import ProjectInfo from "../project-info/ProjectInfo";
import { useSearchParams } from "react-router-dom";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { ApiDataType, PlanningWeekDayType } from "types";
import { monday, sunday } from "helpers/currentWeek";
import { useEffect, useState } from "react";
import { PPageInfo, PProjectInfo, PlannedInfo } from "components/planning/dto";
import Skeleton from "react-loading-skeleton";
import CalendarDatePicker from "../calendar-date-picker/CalendarDatePicker";
import Swiper from "swiper";

const Main = () => {
  const [thumb, setThumb] = useState<Swiper[]>([]);
  const [data, setData] = useState<PlannedInfo[] | null>(null);
  const [tableDays, setTableDays] = useState<ApiDataType<
    PlanningWeekDayType[],
    string[]
  > | null>(null);
  const [pageInfo, setPageInfo] = useState<PPageInfo | null>(null);
  const [projectInfo, setProjectInfo] = useState<
    PProjectInfo | null | undefined
  >(null);

  const [searchParams] = useSearchParams();
  const { apiGet } = useApi();

  const fetchData = async () => {
    let projectInfoRes: ApiDataType<PProjectInfo, string[]> | null = null;
    const pageInfoParams = Object.assign(
      {},
      searchParams.get("time_unit") && {
        time_unit: searchParams.get("time_unit"),
      },
      {
        date_start: searchParams.get("date_start") || monday,
        date_end: searchParams.get("date_end") || sunday,
      },
    );

    const projectInfoParams = Object.assign(
      pageInfoParams,
      searchParams.get("project") && { project: searchParams.get("project") },
    );

    const plannedInfoParams = Object.assign(
      projectInfoParams,
      searchParams.get("department") && {
        department: searchParams.get("department"),
      },
      searchParams.get("position") && {
        position: searchParams.get("position"),
      },
      searchParams.get("employee") && {
        employee: searchParams.get("employee"),
      },
    );

    const pageInfoRes = await apiGet(endpoints.planningTotal, pageInfoParams);
    if (searchParams.get("project"))
      projectInfoRes = await apiGet(
        endpoints.planningProject,
        projectInfoParams,
      );
    const plannedInfoRes = await apiGet(
      endpoints.planningEmployeeList,
      plannedInfoParams,
    );

    setData(plannedInfoRes.data);
    setPageInfo(pageInfoRes.data);
    setProjectInfo(projectInfoRes?.data || null);
  };

  const fetchTableDays = async (monday: string, sunday: string) => {
    const res = await apiGet(endpoints.planningDayList, {
      date_start: monday,
      date_end: sunday,
    });
    setTableDays(res);
  };

  useEffect(() => {
    const thisMonday = searchParams.get("date_start") || monday;
    const thisSunday = searchParams.get("date_end") || sunday;

    setData(null);
    if (searchParams.get("project")) {
      setProjectInfo(undefined);
    } else {
      setProjectInfo(null);
    }

    fetchData();
    fetchTableDays(thisMonday, thisSunday);
  }, [searchParams]);

  const skeleton = {
    count: 4,
    style: {
      height: "100px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeleton,
  };

  const projectSkeleton = {
    count: 1,
    style: {
      height: "70px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeleton,
  };

  const tableMaxHeight = projectInfo
    ? { maxHeight: "calc(100vh - 442px)" }
    : { maxHeight: "calc(100vh - 342px)" };

  return (
    <div className={classes.container}>
      {projectInfo ? (
        <ProjectInfo data={projectInfo} />
      ) : (
        projectInfo === undefined && (
          <Skeleton
            containerClassName={projectSkeleton.containerClassName}
            count={projectSkeleton.count}
            style={projectSkeleton.style}
          />
        )
      )}
      <div className={classes.header}>
        <CalendarDatePicker />
        {tableDays?.data && <TableHeader data={tableDays.data} thumb={thumb} />}
      </div>
      <div className={classes.table} style={tableMaxHeight}>
        {data ? (
          data.map((i: PlannedInfo, idx: number) => {
            return <TableItem key={idx} data={i} setThumb={setThumb} />;
          })
        ) : (
          <Skeleton
            containerClassName={skeleton.containerClassName}
            count={skeleton.count}
            style={skeleton.style}
          />
        )}
      </div>
      <PageInfo
        plannedHours={pageInfo?.total_hours || 0}
        unassignedHours={pageInfo?.free_hours || 0}
        weeklyProjects={pageInfo?.total_projects || 0}
      />
    </div>
  );
};

export default Main;
