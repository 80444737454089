import classes from "./TableItem.module.scss";
import User from "../user/User";
import UserEmployment from "../user-employment/UserEmployment";
import { PlannedInfo } from "components/planning/dto";
import Swiper from "swiper";
import { Dispatch, SetStateAction } from "react";

interface Props {
  data: PlannedInfo;
  setThumb: Dispatch<SetStateAction<Swiper[]>>;
}

const TableItem = ({ data, setThumb }: Props) => {
  return (
    <div className={classes.container}>
      <User
        data={data.employee}
        employmentPercentage={data.employment_percentage}
        projectCount={data.project_count}
      />
      <UserEmployment
        data={data.bookings}
        id={data.employee.id}
        setThumb={setThumb}
      />
    </div>
  );
};

export default TableItem;
