import classes from "./Search.module.scss";

interface SearchProps {
  clazz?: string;
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  form?: string;
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialValue?: string;
  autoFocus?: boolean;
}

const Search = ({
  clazz,
  error,
  required,
  placeholder = "Название проекта",
  form,
  onInput,
  initialValue,
  autoFocus,
}: SearchProps) => {
  return (
    <input
      autoFocus={autoFocus}
      type="search"
      placeholder={placeholder}
      required={required}
      form={form}
      className={`${classes.search} ${error ? classes["search--error"] : ""} ${
        clazz ? clazz : ""
      }`}
      onInput={onInput}
      defaultValue={initialValue}
      key={initialValue}
    />
  );
};

export default Search;
