import classes from "./Logging.module.scss";
import Form from "forms/components/form";
import PlusFill from "components/ui/icons/PlusFill";
import CrossFill from "components/ui/icons/CrossFill";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { ApiDataType, FormDataType } from "types";
import { UserInfoContext } from "context/UserInfoContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { dateToRFC } from "helpers/dates";
import LoggingTable from "components/tables/LoggingTable/LoggingTable";
import { useContext, useState } from "react";

const Logging = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [updateTable, setUpdateTable] = useState<boolean>();
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);

  const { userInfoContext } = useContext(UserInfoContext);

  let classNameAddTask, iconComponent;

  if (isFormOpen) {
    classNameAddTask = classes["addTask--open"];
    iconComponent = <CrossFill clazz={classes.cross} />;
  } else {
    classNameAddTask = classes.addTask;
    iconComponent = <PlusFill clazz={classes.plus} />;
  }

  const userId = userInfoContext?.data.id;

  const { apiPost } = useApi();

  const onSubmit = async (form: FormDataType, reset?: () => void) => {
    const loggingData = Object.assign({}, form, {
      date: form.date ? dateToRFC(form.date as Date) : dateToRFC(new Date()),
    });

    const res = await apiPost(
      endpoints.addUserLogging,
      loggingData,
      undefined,
      true,
    );
    setResDataAfterSubmit(res);

    if (res.data) {
      setUpdateTable(true);
      reset?.();
    }
  };

  const skeleton = {
    count: 1,
    style: {
      width: "100%",
      height: "24px",
    },
    containerClassName: classes.skeleton,
  };

  const skeletonTable = {
    count: 10,
    style: {
      height: "40px",
      marginBottom: "16px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeletonTable,
  };

  return (
    <div className={classes.container}>
      {userId ? (
        <LoggingTable
          apiUrl={`${endpoints.userLoggingList}${userId}`}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
          pagination={true}
          editable={!isFormOpen}
          skeleton={skeletonTable}
          onlyOnceSkeleton={true}
        >
          {
            <div className={classes.formContainer}>
              <div className={classNameAddTask}>
                <div onClick={() => setIsFormOpen(!isFormOpen)}>
                  {iconComponent}
                </div>
              </div>
            </div>
          }
          {isFormOpen && (
            <Form
              formId="loggingAddTaskForm"
              apiUrl={endpoints.loggingAddTask}
              classValueForm={classes.form}
              btnText=" "
              classValueBtn={classes.formBtn}
              onSubmit={onSubmit}
              skeleton={skeleton}
              resDataAfterSubmit={resDataAfterSubmit}
            />
          )}
        </LoggingTable>
      ) : (
        <Skeleton
          containerClassName={skeletonTable.containerClassName}
          count={skeletonTable.count}
          style={skeletonTable.style}
        />
      )}
    </div>
  );
};

export default Logging;
