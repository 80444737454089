import Clock from "components/ui/icons/Clock";
import Clock2 from "components/ui/icons/Clock2";
import Display from "components/ui/icons/Display";
import classes from "./PageInfo.module.scss";

interface PageInfoProps {
  plannedHours: number;
  unassignedHours: number;
  weeklyProjects: number;
}

const PageInfo = ({
  plannedHours,
  unassignedHours,
  weeklyProjects,
}: PageInfoProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.planned}>
        <Clock clazz={classes.icon} />
        <span className={classes.planned__label}>Запланировано:</span>
        <span className={classes.planned__value}>{`${plannedHours} час`}</span>
      </div>
      <div className={classes.unassigned}>
        <Clock2 clazz={classes.icon} />
        <span className={classes.unassigned__label}>Не распределено:</span>
        <span
          className={classes.unassigned__value}
        >{`${unassignedHours} час`}</span>
      </div>
      <div className={classes.projects}>
        <Display clazz={classes.icon} />
        <span className={classes.projects__label}>Проектов на неделю:</span>
        <span className={classes.projects__value}>{weeklyProjects}</span>
      </div>
    </div>
  );
};

export default PageInfo;
