import classes from "./PlanningManager.module.scss";
import Filters from "./filters/Filters";
import Main from "./main/Main";

const PlanningManager = () => {
  return (
    <div className={classes.container}>
      <Filters />
      <Main />
    </div>
  );
};

export default PlanningManager;
